.colours label {
  max-width: 150px;
  border: solid 1px #eee;
  margin: 10px;
  margin-left: 0;
  padding: 10px;
  border-radius: 15px;
}

.colours img {
  width: 100%;
  opacity: 0.5;
}

.colours .form-check-input:checked + img {
  opacity: 1;
}

.colours .form-check-input {
  display: none;
}

.colours h3 {
  margin-bottom: 0;
}
