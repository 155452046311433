.minicart th {
  border-bottom: solid 2px #fbbe01;
  padding-bottom: 5px;
  font-weight: 400;
}

.btn-remove {
  font-weight: 600;
  font-size: 24px;
  padding: 0;
  color: red;
  vertical-align: middle;
}

.cart-delivery-address strong,
.cart-style-type {
  display: block;
  font-size: 18px;
  font-weight: 600;
}

.cart-delivery-address strong {
  text-transform: uppercase;
}

.cart-style-type {
  font-size: 16px;
}

.expandable {
  text-align: center;
}

.not-selected {
  opacity: 0.5;
}
