.terms canvas {
  background: #eee;
}

.terms .btn-map {
  margin-bottom: 15px;
}

.terms .btn-primary {
  display: block;
  margin-top: 15px;
  background: #fbbe01;
  color: white;
  border-color: white;
}

.terms {
  overflow-y: scroll;
  max-height: 250px;
}
