.editing-mode {
  text-align: center;
  padding: 5px;
  background: lightcoral;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  color: white;
}
.c_exit_text {
  color: blue;
  cursor: pointer;
}