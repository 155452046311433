.sizes .form-group {
  width: auto;
  display: inline-block;
  max-width: 100px;
  margin-right: 15px;
}

.sizes input {
  max-width: 75px;
}
