.cart-totals {
  font-weight: 600;
  background: #eee;
  padding: 10px;
  width: auto;
  margin-bottom: 15px;
}

.cart-totals p {
  margin: 0;
}

.total-value span {
  text-align: right;
  font-weight: 300;
  float: right;
}
