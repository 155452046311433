.season .form-control {
  max-width: 150px;
  display: inline-block;
}

.season .field {
  display: inline-block;
  margin-left: 15px;
}

.season label {
  font-weight: 600;
  font-size: 1.25em;
}

.season {
  text-align: center;
}
