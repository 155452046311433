.address-book .form-group.country {
  display: block;
  width: 40%;
}

.address-book .btn-primary {
  margin-bottom: 20px;
}

.billing.field {
  display: inline-block;
  margin-right: 25px;
}
.billing span {
  vertical-align: middle;
}

.billing .field {
  vertical-align: middle;
  display: inline-block;
}
