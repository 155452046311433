.declaration canvas {
  background: #eee;
}

.declaration .btn-map {
  margin-bottom: 15px;
}

.declaration .btn-primary {
  display: block;
  margin-top: 15px;
  background: #fbbe01;
  color: white;
  border-color: white;
}
