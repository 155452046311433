.address-details th {
  padding: 10px 15px;
  padding-left: 10px;
  border-bottom: solid 2px #fbbe01;
  font-weight: 300;
}

.address-details td {
  padding-left: 10px;
  color: black;
  font-weight: 300;
}

.content button.edit-btn {
  border: none;
}

.content button.edit-btn:hover {
  border: none;
  background: none;
}
