.confirm-customer-btn {
  float: right;
  margin-top: 25px;
  padding: 10px 15px;
  background: #fbbe01;
  color: white;
}

.confirm-customer-btn:hover,
.confirm-customer-btn:active {
  background: black;
  color: white;
}
