.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1,
h2,
h3,
h4 {
  font-weight: 300;
  margin-bottom: 15px;
}

h1 {
  text-align: center;
  margin-bottom: 40px;
}

.back-btn {
  left: 20px;
  border: solid 1px black;
  padding: 5px 10px;
}

.btn-secondary {
  background: white;
  border: solid 1px black;
  color: black;
}

.btn-primary {
  background: #fbbe01;
  border-color: #fbbe01;
}

.btn-primary:hover,
.btn-primary:active {
  background: black !important;
  border: black solid 1px !important;
}

.container {
  display: flex;
  width: 100%;
  margin-top: 40px;
}

.inner-container {
  display: flex;
  flex-basis: auto;
  max-width: 800px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.no-connection {
  color: red;
  font-style: italic;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
