.style-list-page {
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.style-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}

.style-item {
  list-style: none;
  border-radius: 25px;
  display: inline-block;
  flex: 1 0 21%;
  margin: 1%;
  padding: 1%;
  text-align: center;
  /* box-shadow: #efefef 4px 4px 4px 1px; */
  box-shadow: #efefef 2px 2px 15px 0.5px;
  transition: all 1s;
  max-width: 184px;
}

.style-item:hover {
  transform: scale(1.1);
  box-shadow: #efefef 4px 4px 4px 4px;
}

.style-item img {
  width: 100%;
}

.style-item .title {
  font-size: 24px;
  margin-bottom: 0;
}

.style-item .price {
  font-size: 20px;
}

.style-item:last-of-type {
  max-width: 184.04px;
}
