.login .inner-container {
  max-width: 400px;
}

.centeredForm {
  padding: 50px;
  border-radius: 25px;
  box-shadow: #efefef 3px 3px 15px 0.5px;
}

.centeredForm h2 {
  font-weight: 300;
  margin-bottom: 15px;
}

.centeredForm button {
  background: orange;
  border: none;
  padding: 10px 15px;
  margin-top: 0px;
}

.centeredForm button:hover,
.centeredForm button:active {
  background: black;
  border: none;
}
