.modal-content-custom {
    padding-bottom: 10px;
    /* padding-top: 10px; */
    height: inherit;
}

.modal-content-custom h2 {
    border-bottom: 1px solid #d4d1d1;
    padding-left: 10px;
    padding-bottom: 10px;
    font-size: 30px;
}

.modal-content-custom .text {
    padding: 10px;
    text-align: right;
}

.modal-content-custom p {
    text-align: left !important;
    font-size: 20px;
    margin-bottom: 10px;
}

.mg-left {
    margin-left: 10px;
}