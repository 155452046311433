.report_page .form-group {
    text-align: left;
    width: 250px;
    display: inline-block;
}

.report_page .season .field {
    margin-left: 0;
    /* display: block; */
    width: 250px;
    /* max-width: 400px; */
}

.report_page .form-control {
    max-width: 250px;
}

.report_page .season label {
    display: block;
}

svg.add_icon {
    font-size: 48px;
    cursor: pointer;
}

svg.remove_icon {
    font-size: 33px;
    margin-left: 7px;
    margin-bottom: 8px;
}

.report_page .form-control.from_date {
    margin-bottom: 10px;
}

input.no_order_date {
    margin-top: 12px;
    margin-right: 8px;
    /* width: auto; */
    /* display: inline-block; */
}

.form-group.save_query {
    display: block;
}

.download_btn {
    display: inline-block;
    margin-left: 10px;
}

.download_btn button {
    padding: 10px;
    background: black;
    color: white;
}

.margin0 {
    margin-top: 0;
}

.report_page {
    padding-bottom: 40px;
}