.header {
  min-height: 50px;
  border-bottom: solid 5px #fbbe01;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  vertical-align: middle;
  width: 100%;
  color: black;
}

.app-title {
  font-weight: 300;
  font-size: 24px;
  vertical-align: middle;
  margin: 0 auto;
  display: inline-block;
  padding: 10px;
  padding-bottom: 0;
}
